<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <!--        <v-button text="新增" @click="toAdd"></v-button>-->
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <community-select v-model="searchParam.communityId" label="选择园区" />
        <v-select
          clearable
          :options="auditStatusList"
          v-model="searchParam.auditStatus"
          @change="$refs.list.search()"
          label="审核状态"
        />
        <v-select
          clearable
          :options="showStatusList"
          v-model="searchParam.showStatus"
          @change="$refs.list.search()"
          label="展示状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="审核"
          type="text"
          v-if="scope.row.auditStatus === 0"
          @click="toAudit(scope.row)"
        />
        <v-button
          text="展示到C端"
          type="text"
          v-if="scope.row.auditStatus === 1 && scope.row.showStatus === 0"
          @click="changeShowStatus(scope.row, '1')"
        />
        <v-button
          text="从C端隐藏"
          type="text"
          v-if="scope.row.auditStatus === 1 && scope.row.showStatus === 1"
          @click="changeShowStatus(scope.row, '0')"
        />
      </template>
    </v-list>

    <v-dialog
      title="审核"
      v-model="auditDialogFormVisible"
      width="50%"
      @confirm="doAudit"
    >
      <el-form label-width="100px">
        <el-form-item label="用户名">
          <v-input v-model="tempObj.userName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="建议图片">
          <v-upload :imgUrls.sync="tempObj.proposalPic" disabled />
        </el-form-item>
        <el-form-item label="建议内容">
          <v-input v-model="tempObj.proposalContent" :width="400" disabled />
        </el-form-item>
        <el-form-item label="审核结果">
          <v-select :options="handleStatus" v-model="tempObj.auditStatus" />
        </el-form-item>
        <el-form-item label="奖励积分" v-if="tempObj.auditStatus == '1'">
          <v-input
            v-model="tempObj.bonusPoints"
            type="number"
            :disabled="tempObj.auditStatusOrg != '0'"
          />
        </el-form-item>
        <el-form-item label="备注信息">
          <v-input
            v-model="tempObj.auditRemarks"
            :width="400"
            :maxlength="100"
            :disabled="tempObj.auditStatusOrg != '0'"
          />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createImgVNode } from "@/utils/utils.js";
import { auditStatusOptions, showStatusOptions } from "./map";
import { auditUrl, getCommunityConstructionListUrl } from "./api";

export default {
  name: "communityConstructionList",
  data() {
    return {
      auditStatusList: auditStatusOptions,
      showStatusList: showStatusOptions,
      searchParam: { communityId: null, auditStatus: null, showStatus: null },
      tableUrl: getCommunityConstructionListUrl,
      headers: [
        {
          prop: "communityName",
          label: "园区名称",
        },
        {
          prop: "userName",
          label: "用户姓名",
        },
        {
          prop: "roomName",
          label: "用户房号",
        },
        {
          prop: "proposalContent",
          label: "建议内容",
        },
        {
          prop: "proposalPic",
          label: "建议图片",
          align: "center",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "insertTime",
          label: "提交时间",
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          align: "center",
          formatter: (row, prop) => {
            for (let i = 0; i < auditStatusOptions.length; i++) {
              if (auditStatusOptions[i].value == row.auditStatus) {
                return auditStatusOptions[i].label;
              }
            }
          },
        },
        {
          prop: "auditRemarks",
          label: "审核备注",
        },
        {
          prop: "bonusPoints",
          label: "赠送积分",
          align: "center",
        },
        {
          prop: "showStatus",
          label: "C端是否展示",
          align: "center",
          formatter: (row, prop) => {
            for (let i = 0; i < showStatusOptions.length; i++) {
              if (showStatusOptions[i].value == row.showStatus) {
                return showStatusOptions[i].label;
              }
            }
          },
        },
      ],

      auditDialogFormVisible: false,
      tempObj: {
        id: null,
        userName: null,
        roomId: null,
        roomName: null,
        communityId: null,
        userId: null,
        wishType: null,
        wishPic: null,
        wishContent: null,
        auditStatus: null,
        auditStatusOrg: null,
        auditUser: null,
        auditTime: null,
        auditRemarks: null,
        showStatus: null,
        bonusPoints: null,
      },
    };
  },
  components: {},
  mounted() {},
  computed: {
    ...mapState("app", ["userInfo"]),
    handleStatus() {
      let arr = JSON.parse(JSON.stringify(auditStatusOptions));
      arr.splice(0, 1);
      return arr;
    },
  },
  methods: {
    toAudit(data) {
      this.tempObj = {
        id: data.id,
        userName: data.userName,
        roomId: data.roomId,
        roomName: data.roomName,
        communityId: data.communityId,
        userId: data.userId,
        proposalPic: data.proposalPic,
        proposalContent: data.proposalContent,
        auditStatus: "",
        auditStatusOrg: data.auditStatus,
        auditUser: data.auditUser,
        auditTime: data.auditTime,
        auditRemarks: data.auditRemarks,
        showStatus: data.showStatus,
        bonusPoints: data.bonusPoints,
      };
      this.auditDialogFormVisible = true;
    },
    doAudit() {
      console.log(this.userInfo, "this.userInfo--");
      this.$axios
        .post(`${auditUrl}`, null, {
          params: {
            id: this.tempObj.id,
            adminId: this.userInfo.userId,
            tenantId: this.userInfo.tenantId,
            auditStatus: this.tempObj.auditStatus,
            auditRemarks: this.tempObj.auditRemarks,
            bonusPoints: this.tempObj.bonusPoints,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.auditDialogFormVisible = false;
            this.$message.success("操作成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeShowStatus(data, showStatus) {
      this.$axios
        .post(`${auditUrl}`, null, {
          params: {
            id: data.id,
            tenantId: this.userInfo.tenantId,
            adminId: this.userInfo.userId,
            showStatus: showStatus,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
